import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../i18n/index';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    { path: '*', component: Home },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    document.querySelector('html').setAttribute('lang', i18n.locale);

    next();
});

export default router;
