export default {
    home: {
        explanation: {
            title: 'COME OTTENERE IL TOKEN',
            steps: [
                'Apri Streamlabs OBS sul tuo computer (puoi scaricarlo qui: {0}).',
                'In basso a sinistra, clicca sulla rotellina delle impostazioni.',
                'Vai su "Controllo Remoto" --> Clicca sul QR Code --> "Mostra dettagli".',
                'Il "Token API" è il codice di cui hai bisogno.',
            ],
        },
        form: {
            streamlabsObsToken: 'Token di Streamlabs OBS',
            streamlabsObsIp: 'Indirizzo IP di Streamlabs OBS ',
            streamlabsObsPort: 'Porta Streamlabs OBS',
            button: {
                connect: 'Connetti',
                disconnect: 'Disconnetti',
            },
        },
        actions: {
            TOGGLE_STREAMING: 'Streaming alternato',
            MAKE_SCENE_ACTIVE: '{sceneName} selezionata',
        },
        errors: {
            streamlabsIpNotValid: 'Indirizzo ip non valido',
            streamlabsWrongToken: 'Token di Streamlabs OBS non corretto',
            streamlabsNotConnected: 'Streamlabs OBS non è aperto',
            gatewayUnauthorized: 'Non hai ancora collegato il tuo account Amazon alla skill',
            emptyFields: 'Completa tutti i campi',
            unknown: 'Errore sconosciuto',
        },
    },
};