import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

Vue.config.productionTip = false;

Vue.use(Buefy);

new Vue({
    router,
    render: h => h(App),
    i18n,
}).$mount('#app');
