<template>
  <div class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column" :class="{'is-one-third': this.logged, 'is-one-fifth': !this.logged}">
          <div v-if="logged" class="box">
            <form class="block">
              <b-field :label="$t('home.form.streamlabsObsToken')" label-position="on-border">
                <b-input v-model="form.streamlabsObsToken"></b-input>
              </b-field>
              <b-field :label="$t('home.form.streamlabsObsIp')" label-position="on-border"
                       :type="{ 'is-danger': !isValidIp }"
                       :message="{ [$t('home.errors.streamlabsIpNotValid')]: !isValidIp }">
                <b-input v-model="form.streamlabsObsIp"></b-input>
              </b-field>
              <b-field :label="$t('home.form.streamlabsObsPort')" label-position="on-border">
                <b-numberinput v-model="form.streamlabsObsPort"></b-numberinput>
              </b-field>
              <b-field>
                <p class="control">
                  <b-button :label="connected ? $t('home.form.button.disconnect') : $t('home.form.button.connect')"
                            :type="connected ? 'is-danger' : 'is-primary'" :loading="loading" @click="buttonClick"
                            expanded/>
                </p>
              </b-field>
            </form>
            <div class="block content">
              <h3>{{ $t('home.explanation.title') }}</h3>
              <i18n path="home.explanation.steps[0]" tag="p">
                <a href="https://streamlabs.com/streamlabs-obs" target="_blank">streamlabs.com/streamlabs-obs</a>
              </i18n>
              <p>{{ $t('home.explanation.steps[1]') }}</p>
              <p>{{ $t('home.explanation.steps[2]') }}</p>
              <p>{{ $t('home.explanation.steps[3]') }}</p>
            </div>
          </div>
          <div v-if="!logged" class="box">
            <a href @click="this.login">
              <figure class="image">
                <img alt="Login with Amazon"
                     src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_drkgry_312x64.png">
              </figure>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocketController from '@/services/SocketController';

export default {
  name: 'Home',
  data: function () {
    return {
      form: {
        streamlabsObsToken: '',
        streamlabsObsIp: '127.0.0.1',
        streamlabsObsPort: 59650,
      },
      userId: '',
      logged: false,
      loading: false,
      connected: false,
      socketController: null,
    };
  },
  created() {
    window.addEventListener('beforeunload', async () => {
      if (this.socketController) {
        await this.socketController.disconnect();
      }
    });
  },
  mounted() {
    if (localStorage.config) {
      this.form = JSON.parse(localStorage.config);
    }

    if (localStorage.userId) {
      this.logged = true;
      this.userId = localStorage.userId;
    }
  },
  methods: {
    login: function (evt) {
      evt.preventDefault();

      const component = this;

      const options = {
        scope: 'profile:user_id',
        pkce: true,
        state: Math.random().toString(16).substr(2, 10),
      };
      amazon.Login.authorize(options, function (response) {
        if (response.error) {
          this.$buefy.toast.open({ message: this.$t(response.error), type: 'is-danger' });
          return;
        }

        const accessToken = amazon.Login.retrieveToken();

        if (accessToken && accessToken.access_token) {
          amazon.Login.retrieveProfile(accessToken.access_token, function (response) {
            component.logged = true;
            localStorage.userId = response.profile.CustomerId;
            component.userId = response.profile.CustomerId;
          });
        } else {
          amazon.Login.retrieveToken(response.code, function (response) {
            if (response.error) {
              component.$buefy.toast.open({ message: component.$t(response.error), type: 'is-danger' });
              return;
            }

            amazon.Login.retrieveProfile(response.access_token, function (response) {
              component.logged = true;
              localStorage.userId = response.profile.CustomerId;
              component.userId = response.profile.CustomerId;
            });
          });
        }
      });
    },
    buttonClick: async function () {
      this.loading = true;

      if (this.connected) {
        this.connected = false;

        try {
          await this.socketController.disconnect();
        } catch (err) {

        }

        this.socketController = null;
      } else {
        if (!this.isValidIp || this.form.streamlabsObsToken === '' || this.form.streamlabsObsPort <= 0) {
          this.$buefy.toast.open({ message: this.$t('home.errors.emptyFields'), type: 'is-warning' });
        } else {
          this.socketController = new SocketController({
            streamlabsObsToken: this.form.streamlabsObsToken,
            streamlabsEndpoint: this.streamlabsEndpoint,
            userId: this.userId,
            isDev: this.$route.query.dev,
          });

          try {
            await this.socketController.connect();

            this.socketController.on('actionReceived',
                (event) => this.$buefy.toast.open(
                    this.$t(`home.actions.${event.action}`, { sceneName: event.sceneName })),
            );
            this.socketController.on('close', async () => {
              this.connected = false;

              try {
                await this.socketController.disconnect();
              } catch (err) {

              }

              this.socketController = null;
            });

            this.connected = true;

            const config = {
              streamlabsObsToken: this.form.streamlabsObsToken,
              streamlabsObsIp: this.form.streamlabsObsIp,
              streamlabsObsPort: this.form.streamlabsObsPort,
            };
            localStorage.config = JSON.stringify(config);
          } catch (err) {
            if (err.message !== 'streamlabsNotConnected') {
              this.socketController = null;
              delete localStorage.userId;
              this.logged = false;
              this.$buefy.toast.open(
                  { message: this.$t(`home.errors.${err.message}`), type: 'is-danger' });
            }
          }
        }
      }

      this.loading = false;
    },
  },
  computed: {
    isValidIp: function () {
      return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          this.form.streamlabsObsIp);
    },
    streamlabsEndpoint: function () {
      return `http://${this.form.streamlabsObsIp}:${this.form.streamlabsObsPort}/api`;
    },
  },
};
</script>
