export default {
    home: {
        explanation: {
            title: 'HOW TO RETRIEVE THE TOKEN',
            steps: [
                'Open Streamlabs OBS on your computer (you can download it here: {0}).',
                'Down left, click on the settings gear.',
                'Head over the "Remote Control" --> "Click to reveal" --> "Show details".',
                'The "API Token" is the code you need.',
            ],
        },
        form: {
            streamlabsObsToken: 'Streamlabs OBS token',
            streamlabsObsIp: 'Streamlabs OBS IP address',
            streamlabsObsPort: 'Streamlabs OBS port',
            button: {
                connect: 'Connect',
                disconnect: 'Disconnect',
            },
        },
        actions: {
            TOGGLE_STREAMING: 'Streaming toggled',
            MAKE_SCENE_ACTIVE: '{sceneName} selected',
        },
        errors: {
            streamlabsIpNotValid: 'Ip address is invalid',
            streamlabsWrongToken: 'Streamlabs OBS token is incorrect',
            streamlabsNotConnected: 'Streamlabs OBS is not running',
            gatewayUnauthorized: 'You have not linked your Amazon account to the skill',
            emptyFields: 'Fill all the fields',
            unknown: 'Unknown error',
        },
    },
};