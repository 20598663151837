import WebSocketAsPromised from 'websocket-as-promised';
import SockJS from 'sockjs-client';
import EventEmitter from 'events';

const API_GATEWAY_ENDPOINT = process.env.VUE_APP_WEBSOCKET_ENDPOINT;

export default class SocketController extends EventEmitter {
    constructor({ streamlabsEndpoint, streamlabsObsToken, userId, isDev }) {
        super();

        this.streamlabsObsToken = streamlabsObsToken;
        this.streamlabs = new WebSocketAsPromised(streamlabsEndpoint, {
            createWebSocket: (url) => new SockJS(url),
            packMessage: data => JSON.stringify(data),
            unpackMessage: data => JSON.parse(data),
            attachRequestId: (data, requestId) => {
                return {
                    jsonrpc: '2.0',
                    id: requestId,
                    method: data.method,
                    params: {
                        resource: data.resource,
                        args: data.params,
                    },
                };
            },
            extractRequestId: data => data && data.id,
        });
        this.streamlabs.onClose.addListener((event) => event.reason !== 'disconnect'
            ? this.emit('close')
            : undefined);

        const queryParams = new URLSearchParams({ token: userId });
        const env = isDev
            ? 'dev'
            : 'prod';
        this.gateway = new WebSocketAsPromised(`${API_GATEWAY_ENDPOINT}/${env}?${queryParams.toString()}`, {
            packMessage: data => JSON.stringify(data),
            unpackMessage: data => JSON.parse(data),
        });
        this.gateway.onClose.addListener((event) => event.reason !== 'disconnect'
            ? this.emit('close')
            : undefined);
    }

    connect() {
        return this.connectStreamlabs()
        .then(response => {
            if (response.error) {
                throw new Error('streamlabsWrongToken');
            }

            return this.connectGateway();
        })
        .then(() => Promise.all([this.getScenes(), this.isStreaming()]))
        .then(([scenes, isStreaming]) => {
            scenes = scenes.slice(0, 100);

            this.gateway.onUnpackedMessage.addListener(async (data) => {
                if (data.action) {
                    const { action } = data;

                    const event = { action };
                    if (action === 'TOGGLE_STREAMING') {
                        await this.toggleStreaming();
                    } else if (action === 'MAKE_SCENE_ACTIVE') {
                        await this.makeSceneActive(data.sceneId);
                        event.sceneName = scenes.find((scene) => scene.id === data.sceneId).name;
                    }

                    this.emit('actionReceived', event);
                }
            });

            this.streamlabs.onUnpackedMessage.addListener((data) => {
                const { result } = data;

                if (result._type && result._type === 'EVENT') {
                    const { data } = result;

                    if (data === 'live' || data === 'offline') {
                        this.gateway.sendPacked({ action: 'SET_STREAMING_STATUS', isStreaming: data === 'live' });
                    }
                }
            });

            this.gateway.sendPacked({ action: 'SET_SCENES', scenes });
            this.gateway.sendPacked({ action: 'SET_STREAMING_STATUS', isStreaming });
        }).then(() => this.subscribeStreamingStatus());
    }

    connectStreamlabs() {
        return this.streamlabs.open()
        .catch((err) => {
            throw new Error('streamlabsNotConnected');
        })
        .then(() => this.streamlabs.sendRequest(
            { method: 'auth', resource: 'TcpServerService', params: [this.streamlabsObsToken] }));
    }

    connectGateway() {
        return this.gateway.open()
        .catch((err) => {
            throw new Error('gatewayUnauthorized');
        })
        .then(() => {
            this.pingInterval = setInterval(() => this.gateway.isOpened && this.gateway.sendPacked({ action: 'ping' }),
                300000,
            );
        });
    }

    async disconnect() {
        await Promise.all([this.streamlabs.close(1000, 'disconnect'), this.gateway.close(1000, 'disconnect')]);

        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }
    }

    subscribeStreamingStatus() {
        return this.streamlabs.sendRequest({ method: 'streamingStatusChange', resource: 'StreamingService' }).then(
            response => response.result);
    }

    toggleStreaming() {
        return this.streamlabs.sendRequest({ method: 'toggleStreaming', resource: 'StreamingService' }).then(
            response => response.result);
    }

    isStreaming() {
        return this.streamlabs.sendRequest({ method: 'getModel', resource: 'StreamingService' }).then(
            response => response.result.streamingStatus !== 'ending' && response.result.streamingStatus !== 'offline');
    }

    getScenes() {
        return this.streamlabs.sendRequest({ method: 'getScenes', resource: 'ScenesService' }).then(
            response => response.result).then(result => result.map(scene => {
            return { id: scene.id, name: scene.name };
        }));
    }

    makeSceneActive(sceneId) {
        return this.streamlabs.sendRequest({ method: 'makeSceneActive', resource: 'ScenesService', params: [sceneId] })
        .then(response => response.result);
    }
}
