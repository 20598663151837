import Vue from 'vue';
import VueI18n from 'vue-i18n';
import it from './it';
import en from './en';

Vue.use(VueI18n);

const messages = { it, en };

const i18n = new VueI18n({
    locale: window.navigator.language.substring(0, 2),
    fallbackLocale: 'en',
    messages,
});

export default i18n;
